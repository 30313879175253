import * as React from "react";
import Layout from "../../components/layout";
import { Col, Container, Row } from "react-bootstrap";
import TopImage from "../../components/topImage";

const AboutPage = () => {
  return (
    <Layout>
      <TopImage imageName="about" />

      <Row>
        <Col>
          <h1 className="mb-5">About us</h1>

          <h2 className="mb-3">Who are we?</h2>

          <p>
            We are a non-profit organisation with the goals to offer free speech
            training for people suffering from stuttering and to deepen our
            knowledge about how and why stuttering develops. Our predecessor was
            the PaBaSAT study, in which we have implemented and refined our
            speech training methods and collected a lot of statistical data
            about stuttering and speech patterns.
          </p>
          <p>
            We offer all our services for free because we also use the insights
            gathered from all our patients for further scientific analysis and
            further refinement of our methods. Because of our very good training
            results, the demand is high. Therefore, we unfortunately can’t offer
            an immediate training opportunity for everybody. In case you can’t
            get a training immediately, we will place you on our waiting list.
            Also, we are constantly working on increasing our training capacity.
          </p>
          <p>Contact us now for your personal training program!</p>

          <h2 className="mt-5 mb-3">What is our concept?</h2>

          <p>
            Basically, we believe that stuttering is a trained behavior just as
            fluent speech is. Thus, it should be possible to un-train this
            behavior and relearn a fluent speaking rhythm again.
          </p>
          <p>
            During the so-called PaBaSAT study, which researched stuttering and
            stuttering therapy for many years, we found enough proof for our
            assumptions to be true.
          </p>
          <p>
            We think that common therapy approaches lead to an even deeper
            manifestation of stuttering as it accepts stuttering as not curable
            and instead introduces a behavior that works around the stutter.
          </p>
          <p>
            Our idea is, that every person who stutters has unconsciously
            developed speech patterns that include stuttering. The stutter
            usually does not appear at the same words or sounds every time;
            therefore the underlying patterns are not obvious. Nevertheless, for
            successfully treating stuttering, it is crucial to identify these
            individual patterns.
          </p>
          <p>
            In the past years, we have discovered that a treatment of the
            person-specific speech patterns has a very high chance of success.
            85 % of our long-term participants describe themselves as
            stutter-free over a period of at least two years after their pattern
            based stuttering treatment.
          </p>
          <p>
            While the actual treatment of the stuttering patters is rather easy
            and leads to a significant success quickly, the task of identifying
            the individual patters imposes a very high complexity.
          </p>
          <p>
            To simplify that process, our long-term goal is to develop a
            software which can either identify or at least help to identify
            stuttering patterns of individual persons based on speech samples.
            To accomplish that, we need to create a mathematical representation
            of a huge number of speech or stuttering patterns from which we can
            derive possible pattern recognition algorithms.
          </p>
        </Col>
      </Row>
    </Layout>
  );
};

export default AboutPage;
